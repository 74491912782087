import { Controller, useForm } from 'react-hook-form';
import { IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import React from 'react';
import { ChatFormData, ChatQuestionInputProps } from './ChatWindow.types';
import { useAppSelector } from '../../store/hooks';
import { CHAT_MODELS } from '../../config/config';
import common from '../../assets/common.module.scss';

export const ChatQuestionInput = ({ loadingSelector, onSubmit }: ChatQuestionInputProps) => {
  const loading = useAppSelector(loadingSelector);

  const {
    control,
    handleSubmit: handleFormSubmit,
    formState,
    resetField,
  } = useForm<ChatFormData>({
    mode: 'onChange',
    defaultValues: {
      model: CHAT_MODELS[0].value,
      message: '',
    },
  });

  const handleSubmit = ({ message, model }: ChatFormData) => {
    const input = message.trim();
    if (!input) return;
    onSubmit(input, model);
    resetField('message');
  };

  return (
    <form className='llm-question-input-form' onSubmit={handleFormSubmit(handleSubmit)}>
      <Controller
        name='model'
        control={control}
        render={({ field, fieldState: { error } }) => (
          // <div className='llm-model'>
          //   model: <u>makdm-asdmkdf-sdkfmsd-123</u>
          // </div>
          <TextField
            {...field}
            // fullWidth
            disabled={formState.isSubmitting || loading}
            error={!!error}
            helperText={error ? error.message : ''}
            // label='Model'
            variant='outlined'
            size='small'
            select
            SelectProps={{
              renderValue: (v) => `Model: ${CHAT_MODELS.find(({ value }) => value === v)?.label}`,
              MenuProps: { sx: { zIndex: common.zIndexMuiSnackbar } },
            }}
            className='llm-model-select'
          >
            {CHAT_MODELS.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <Controller
        name='message'
        rules={{ required: true }}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            multiline
            disabled={formState.isSubmitting || loading}
            error={!!error}
            helperText={error ? error.message : ''}
            placeholder='Type your message...'
            variant='outlined'
            className='llm-message-input'
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleFormSubmit(handleSubmit)();
              }
            }}
          />
        )}
      />

      <Tooltip title='Send message to the assistant'>
        <div className='llm-submit-button'>
          {/*<IconButton type='submit' disabled={!formState.isValid || !formState.isDirty}>*/}
          <IconButton type='submit' disabled={!formState.isValid}>
            <SendIcon />
          </IconButton>
        </div>
      </Tooltip>
    </form>
  );
};
