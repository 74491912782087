import Grid from '@mui/material/Grid';
import { LanguageToggleSwitch } from '../../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch';
import { DocumentDownloadButton } from '../../Buttons/DocumentDownloadButton';
import { DocumentInfoHeader } from '../../../StaticComponents/DocumentInfoHeader/DocumentInfoHeader';
import React, { useCallback, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { MetadataPanel } from '../../DocumentViewModal/MetadataPanel';
import { useLanguageToggleSwitch } from '../../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.hook';
import { LanguageToggleSwitchTypes } from '../../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import { AchDocumentViewer } from '../../../UploadFiles/MetadataEditModal/AchDocumentViewer';
import { ClauseMetadataPanel } from '../../../Clauses/ClauseViewModal/ClauseMetadataPanel';
import { DocumentComparePanelProps } from './DocumentComparePanel.types';
import { ClauseViewer } from '../../../Clauses/ClauseViewModal/ClauseViewer';
import './DocumentComparePanel.scss';
import { uiSelectors } from '../../../../store/ui/ui.selectors';
import { useAppSelector } from '../../../../store/hooks';
import { setLockedLanguage } from '../../../../store/ui/ui.slice';
import { useAppDispatch } from '../../../../store/hooks';
import { Order } from '../../../StaticComponents/FileViewer/useDocumentCompare';

export const DocumentComparePanel = React.forwardRef<HTMLDivElement, DocumentComparePanelProps>(
  ({ documentDictionaries, document, clauseDictionaries, clause, className = '', order }, ref) => {
    const itemId = document ? document.DocumentId : clause!.ClauseId;
    const documentInfoHeaderProps = document ? { document } : { clause };
    const dispatch = useAppDispatch();

    const [language, setLanguage, pdf] = useLanguageToggleSwitch(itemId, !!document);
    const isLanguageLocked = useAppSelector(uiSelectors.selectLockLanguage);
    const llmChatMode = !!useAppSelector(uiSelectors.selectModalChat);

    const handleLanguageChange = useCallback(
      (lng: LanguageToggleSwitchTypes) => {
        if (isLanguageLocked && !llmChatMode) {
          dispatch(setLockedLanguage(lng));
          return;
        }
        setLanguage(lng);
      },
      [dispatch, isLanguageLocked, setLanguage, llmChatMode]
    );

    const [compareMetadataPanel, setCompareMetadataPanel] = useState<boolean>(false);
    const toggleCompareMetadataPanel = () => {
      setCompareMetadataPanel(!compareMetadataPanel);
    };
    return (
      <Grid item xs className={`document-compare ${className}`} ref={ref}>
        <div className='document-compare-header'>
          <LanguageToggleSwitch
            value={language}
            onChange={handleLanguageChange}
            pdf={pdf}
            lockingEnabled={!llmChatMode}
          />
          <span className='document-compare-menu'>
            {/*TODO - download clause?*/}
            {!!document && !llmChatMode && <DocumentDownloadButton document={document} />}
          </span>
        </div>
        <div className='document-compare-doc-info-header'>
          <DocumentInfoHeader {...documentInfoHeaderProps} showProvider={compareMetadataPanel} />
          <span className='toggle-content-switch'>
            <FormControlLabel
              control={
                <Switch checked={compareMetadataPanel} onChange={toggleCompareMetadataPanel} />
              }
              label='Show metadata'
            />
          </span>
        </div>
        <div className={'document-compare-window ' + (compareMetadataPanel ? 'metadata-list' : '')}>
          {compareMetadataPanel ? (
            document ? (
              <MetadataPanel dictionaries={documentDictionaries!} document={document} />
            ) : (
              <ClauseMetadataPanel dictionaries={clauseDictionaries!} clause={clause!} />
            )
          ) : document ? (
            <AchDocumentViewer
              documentId={document?.DocumentId}
              language={language}
              order={order as Order}
            />
          ) : (
            <div className='clause-view-modal-text'>
              <ClauseViewer clauseId={itemId} language={language} order={order} />
            </div>
          )}
        </div>
      </Grid>
    );
  }
);
