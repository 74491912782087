import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { Backdrop, Tooltip } from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { documentsSelectors } from '../../../store/files/documents/documents.selectors';
import {
  clearSelectedText,
  closeDocumentViewModal,
} from '../../../store/files/documents/documents.slice';
import { useGetDocumentsListForVersionsWithParams } from '../../../store/files/documents/documents.hooks';

import './DocumentViewModal.scss';
import { DocumentContextMenuButton } from '../DocumentContextMenu/DocumentContextMenuButton';
import { Document, DocumentStatus } from '../../../store/files/documents/documents.list.types';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { LanguageToggleSwitch } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch';
import { DocumentInfoHeader } from '../../StaticComponents/DocumentInfoHeader/DocumentInfoHeader';

import { DocumentContextMenu } from '../DocumentContextMenu/DocumentContextMenu';
import { useContextMenu } from '../DocumentContextMenu/DocumentContextMenu.hooks';
import {
  MenuItemsDocumentReportedViewModal,
  MenuItemsDocumentViewModal,
} from '../DocumentContextMenu/DocumentContextMenu.helpers';
import { useGetDictionariesQueryDefaults } from '../../../store/files/upload/list.service.hooks';
import { useGetCommentsQuery } from '../../../store/files/comments/comments.service';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Compare } from './Compare';
import { MinimizeItem } from '../../StaticComponents/Modals/MinimizeItem/MinimizeItem';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { MetadataPanel } from './MetadataPanel';
import { useLanguageToggleSwitch } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.hook';
import { AchDocumentViewer } from '../../UploadFiles/MetadataEditModal/AchDocumentViewer';
import { MINIMIZED_DATA } from '../../../store/ui/ui.types';
import { Comments as CommentsList } from '../../Comments/Comments';
import { RoutePath } from '../../../services/route.service';
import { DocumentViewModalProps } from '../Documents.types';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import {
  SEMI_AUTO_CLAUSE_CREATE_TOOLTIPS,
  useCanCreateNewDocumentVersion,
  useCanCreateSemiAutoClause,
  useGetRelatedClauses,
} from './DocumentViewModal.hooks';
import { DocumentRelatedClauses } from './DocumentRelatedClauses/DocumentRelatedClauses';
import {
  getLastNotEmptyQuery,
  handleCloseDocumentViewModal,
  handleCloseDocumentViewModalOnly,
} from './DocumentViewModal.helpers';
import { DocumentTabItems } from './DocumentTabItems/DocumentTabItems';
import { getColumnsVersions } from './DocumentTabItems/DocumentTabItems.helpers';
import { useDocumentTabItemsHook } from './DocumentTabItems/DocumentTabItems.hooks';
import { openChatModal, setScrollToClauseInDoc } from '../../../store/ui/ui.slice';
import { hasMinimumWords } from '../../UploadClause/UploadClause.helpers';
import { MIN_CLAUSE_WORD_COUNT_MSG } from '../../../config/config';
import AddNewVersionButton from './AddNewVersionButton/AddNewVersionButton';
import { useFetchAndDispatchDocument } from './useFetchAndDispatchDocument.hook';

export const DocumentViewModal = ({ useGetDataListWithParams }: DocumentViewModalProps) => {
  useFetchAndDispatchDocument();

  const navigate = useNavigate();
  const navigateToUploadClause = () => {
    navigate(RoutePath.UPLOAD_CLAUSE, { state: { document } });
  };

  const [minimize, setMinimize] = useState(false);
  const document = useAppSelector(documentsSelectors.selectModalDocumentView);
  const selectedText: string = useAppSelector(documentsSelectors.selectSelectedText);
  const hasEnoughWords = hasMinimumWords(selectedText);
  const { data: { Documents } = {} } = useGetDocumentsListForVersionsWithParams(
    document?.VersionKey ?? ''
  );

  const {
    data: { Comments = [] } = {},
    isFetching,
    refetch,
  } = useGetCommentsQuery(
    { itemType: 'document', id: document?.DocumentId ?? '' },
    {
      skip: !document?.DocumentId,
    }
  );

  const clauses = useGetRelatedClauses(document);

  const versions = Documents?.filter(
    ({ DocumentId, VersionKey }) =>
      DocumentId !== document?.DocumentId && VersionKey === document?.VersionKey
  );

  const [tabValue, setTabValue] = useState(0);

  const dispatch = useAppDispatch();
  const { control, handleContextMenu } = useContextMenu<Document>();
  const { filters } = useGetDataListWithParams();
  const { SearchQuery, OriginalLanguage, SearchMethod } = getLastNotEmptyQuery(filters.Queries);

  const [language, setLanguage] = useLanguageToggleSwitch(
    document?.DocumentId,
    true,
    SearchQuery,
    OriginalLanguage
  );
  const EnglishTextTabSelected = language === 2;
  const { pathname } = useLocation();
  const { id } = useParams();
  const reported = pathname.includes(RoutePath.REPORTED_DOCUMENTS);
  const documentContextMenuItems = reported
    ? MenuItemsDocumentReportedViewModal
    : MenuItemsDocumentViewModal;
  const { data: readAccessDictionaries } = useGetDictionariesQueryDefaults({
    AccessMode: AccessMode.READ,
  });

  const canCreateSemiAutoClause = useCanCreateSemiAutoClause(document);
  const canCreateNewVersion = useCanCreateNewDocumentVersion(document);

  useEffect(() => {
    if (!canCreateNewVersion && !versions?.length && tabValue === 1) {
      setTabValue(0);
    }
  }, [Comments?.length, isFetching, tabValue, versions?.length, canCreateNewVersion]);

  useEffect(() => {
    refetch();
  }, [document?.VersionKey, refetch]);

  const handleClose = () => {
    document && handleCloseDocumentViewModal(dispatch, navigate, id, document);
  };

  const handleCloseModalOnly = () => {
    document && handleCloseDocumentViewModalOnly(dispatch, navigate, id, document);
  };

  const onMinimize = () => {
    handleCloseModalOnly();
  };

  const handleContextMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    handleContextMenu(event, document!);
  };

  const handleChatClick = () => {
    if (document) {
      dispatch(openChatModal({ document }));
    }
  };

  const handleAddClauseClick = () => {
    navigateToUploadClause();
    dispatch(closeDocumentViewModal());
    dispatch(setScrollToClauseInDoc(undefined));
  };

  const handleChange = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  if (!readAccessDictionaries || !document) {
    if (tabValue !== 0) {
      setTabValue(0);
    }
    return null;
  }

  return (
    <Modal
      open={!!document?.DocumentId}
      onClose={handleCloseModalOnly}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 0,
      }}
    >
      <div className={`metadata-edit-modal document-view-modal ${minimize ? 'minimize-box' : ''}`}>
        <Grid container>
          <Grid item xs className='document-view'>
            <div className='document-view-modal__top-left-bar'>
              <LanguageToggleSwitch
                value={language}
                onChange={(newValue) => {
                  setLanguage(newValue);
                  dispatch(clearSelectedText());
                }}
                pdf
              />
              <div className='document-view-menu'>
                {!EnglishTextTabSelected && !document.IsDraft && (
                  <Tooltip
                    title={
                      !canCreateSemiAutoClause
                        ? SEMI_AUTO_CLAUSE_CREATE_TOOLTIPS.INSUFFICIENT_RIGHTS
                        : document.Reported
                        ? SEMI_AUTO_CLAUSE_CREATE_TOOLTIPS.DOCUMENT_REPORTED
                        : !selectedText
                        ? SEMI_AUTO_CLAUSE_CREATE_TOOLTIPS.SELECT_TEXT
                        : !hasEnoughWords
                        ? MIN_CLAUSE_WORD_COUNT_MSG
                        : ''
                    }
                  >
                    <span>
                      <Button
                        disabled={
                          !selectedText ||
                          !canCreateSemiAutoClause ||
                          document.Reported ||
                          !hasEnoughWords
                        }
                        onClick={handleAddClauseClick}
                        variant='text'
                        startIcon={<PlaylistAddIcon className='document-view-icons' />}
                      >
                        Create clause
                      </Button>
                    </span>
                  </Tooltip>
                )}
                <Compare
                  documentId={document.DocumentId}
                  name={document.FileName}
                  dataType={MINIMIZED_DATA.MINIMIZED_DOCS}
                />
                <Button
                  disabled={reported}
                  onClick={handleChatClick}
                  variant='text'
                  startIcon={<ForumOutlinedIcon className='document-view-icons' />}
                >
                  AI Chat
                </Button>
                <DocumentContextMenuButton
                  disabled={document?.Status === DocumentStatus.DELETING}
                  onClick={handleContextMenuOpen}
                />
              </div>
            </div>

            <AchDocumentViewer
              documentId={document?.DocumentId}
              language={language}
              keyword={SearchQuery}
              method={SearchMethod}
              searchOption
              clauses={clauses}
            />
          </Grid>

          <Grid item className='form-view'>
            <div className='document-view-modal__top-right-bar'>
              <div className='document-view-header'>
                <Tabs value={tabValue} onChange={handleChange} className='document-view-tabs'>
                  <Tab
                    data-test-id={TEST_ID.DOCUMENT_VIEW_MODAL.DETAILS_TAB}
                    value={0}
                    label='Details'
                  />

                  {!document.IsDraft && (
                    <Tab
                      data-test-id={TEST_ID.DOCUMENT_VIEW_MODAL.VERSIONS_TAB}
                      value={1}
                      label={!versions?.length ? 'Versions' : `Versions (${versions?.length})`}
                      disabled={!(versions?.length || canCreateNewVersion)}
                    />
                  )}
                  <Tab
                    data-test-id={TEST_ID.DOCUMENT_VIEW_MODAL.CLAUSES_TAB}
                    value={2}
                    label={`Clauses (${clauses.length})`}
                    disabled={!clauses.length}
                  />
                  <Tab
                    data-test-id={TEST_ID.DOCUMENT_VIEW_MODAL.COMMENTS_TAB}
                    value={3}
                    label={!Comments?.length ? 'Comments' : `Comments (${Comments?.length})`}
                  />
                </Tabs>
              </div>
              <MinimizeItem item={document} onMinimize={onMinimize} setMinimize={setMinimize} />
              <ModalClose onClose={handleClose} />
            </div>

            <TabContext value={String(tabValue)}>
              <TabPanel value='0'>
                <DocumentInfoHeader document={document} />
                <MetadataPanel dictionaries={readAccessDictionaries} document={document} />
              </TabPanel>
              <TabPanel value='1'>
                {canCreateNewVersion && <AddNewVersionButton document={document} />}
                {!!versions?.length && (
                  <DocumentTabItems
                    items={versions ?? []}
                    dictionaries={readAccessDictionaries}
                    documentHook={useDocumentTabItemsHook}
                    columnFn={() =>
                      getColumnsVersions(readAccessDictionaries, {
                        enableCompareButton: true,
                        showVersion: true,
                        showBoolFlags: true,
                      })
                    }
                  />
                )}
              </TabPanel>
              <TabPanel value='2'>
                <DocumentRelatedClauses clauses={clauses} />
              </TabPanel>
              <TabPanel value='3'>
                <CommentsList
                  comments={Comments ?? []}
                  itemId={document?.DocumentId}
                  itemType='document'
                  isDataFetching={isFetching}
                />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
        <DocumentContextMenu
          control={control}
          documentContextMenuItems={documentContextMenuItems}
        />
      </div>
    </Modal>
  );
};
