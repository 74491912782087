import React, { useState } from 'react';
import './ChatModal.scss';
import './Resizer.scss';
import Modal from '@mui/material/Modal';
import { Backdrop } from '@mui/material';
import { closeChatModal } from '../../store/ui/ui.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ModalCloseButton } from '../StaticComponents/Modals/ModalCloseButton';
import { DocumentComparePanel } from '../Documents/DocumentCompareModal/DocumentComparePanel/DocumentComparePanel';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { useGetDictionariesQueryDefaults } from '../../store/files/upload/list.service.hooks';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import SplitPane from 'react-split-pane';
import ChatWindow from './ChatWindow';
import { clearChat } from '../../store/files/chat/documentChat.slice';
import {
  CLOSE_CHAT_MODAL_CANCEL_BUTTON_LABEL,
  CLOSE_CHAT_MODAL_MESSAGE,
  CLOSE_CHAT_MODAL_SUBMIT_BUTTON_LABEL,
  CLOSE_CHAT_MODAL_TITLE,
} from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import { ConfirmationModal } from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal';

export const ChatModal = () => {
  const dispatch = useAppDispatch();
  const chatDocument = useAppSelector(uiSelectors.selectModalChat);
  const { data: dictionaries } = useGetDictionariesQueryDefaults({ AccessMode: AccessMode.READ });
  const [confirmation, setConfirmation] = useState(false);

  const handleClose = () => {
    setConfirmation(true);
  };

  const handleConfirmationClose = () => {
    setConfirmation(false);
  };

  const handleConfirm = () => {
    dispatch(closeChatModal());
    dispatch(clearChat());
  };

  if (!chatDocument) {
    return null;
  }

  return (
    <>
      <Modal
        open={!!chatDocument}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 0,
        }}
        classes={{
          root: 'chat-modal-root',
        }}
      >
        <div className='chat-modal'>
          <SplitPane
            split='vertical'
            minSize={330}
            maxSize={window.innerWidth - 330}
            defaultSize='67%'
            style={{ height: '100%', position: 'relative' }}
          >
            <div className='chat-pane'>
              <DocumentComparePanel document={chatDocument} documentDictionaries={dictionaries} />
            </div>
            <div className='chat-pane'>
              <ChatWindow documentId={chatDocument.DocumentId} />
            </div>
          </SplitPane>

          <ModalCloseButton onClose={handleClose} tooltip='Close chat window' />
        </div>
      </Modal>

      <ConfirmationModal
        open={confirmation}
        onClose={handleConfirmationClose}
        onConfirm={handleConfirm}
        title={CLOSE_CHAT_MODAL_TITLE}
        message={CLOSE_CHAT_MODAL_MESSAGE}
        cancelButtonLabel={CLOSE_CHAT_MODAL_CANCEL_BUTTON_LABEL}
        successButtonLabel={CLOSE_CHAT_MODAL_SUBMIT_BUTTON_LABEL}
      />
    </>
  );
};
