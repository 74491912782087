import React from 'react';
import './ChatWindow.scss';
import { IconButton, Tooltip } from '@mui/material';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import gwrLogo from '../../assets/icons/gwr_logo.svg';
import { ChatMessageStream } from './ChatMessageStream';
import { ChatQuestionInput } from './ChatQuestionInput';
import { ChatLoading } from './ChatLoading';
import { useAppDispatch } from '../../store/hooks';
import { askQuestion, clearChat } from '../../store/files/chat/documentChat.slice';
import { documentChatSelectors } from '../../store/files/chat/documentChat.selectors';
import { ChatError } from './ChatError';
import { ChatWindowProps } from './ChatWindow.types';

const ChatWindow = ({ documentId }: ChatWindowProps) => {
  const dispatch = useAppDispatch();

  const handleClearChat = () => {
    dispatch(clearChat());
  };

  const handleSubmit = (Question: string, IdModel: string) => {
    dispatch(askQuestion({ DocumentId: documentId, Question, IdModel }));
  };

  return (
    <div className='chat-container'>
      <div className='chat-header'>
        <img src={gwrLogo} alt='GWR Logo' className='chat-logo' />
        <span>AI Assistant</span>
      </div>

      <ChatMessageStream messagesSelector={documentChatSelectors.selectMessages} />

      <ChatError errorSelector={documentChatSelectors.selectError} />

      <ChatLoading loadingSelector={documentChatSelectors.selectLoading} />

      <div className='llm-question-input-container'>
        <Tooltip title='Delete a conversation'>
          <IconButton className='llm-clear-button' onClick={handleClearChat} type='button'>
            <DeleteOutlined />
          </IconButton>
        </Tooltip>

        <ChatQuestionInput
          loadingSelector={documentChatSelectors.selectLoading}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ChatWindow;
