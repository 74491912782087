import { getRoutePath, RoutePath } from '../../../../services/route.service';
import { DocumentResponse } from '../../../../store/files/documents/documents.list.types';
import { ClauseResponse } from '../../../../store/files/clauses/clauses.list.types';

export const getDocumentPath = (IsDraft: boolean) =>
  IsDraft ? RoutePath.SANDBOX_DOCUMENTS : RoutePath.DOCUMENTS;

export const openNewDocumentViewModal = ({ DocumentId, IsDraft }: DocumentResponse) => {
  window.open(getRoutePath(getDocumentPath(IsDraft), DocumentId as RoutePath), '_blank');
};

export const getClausePath = (IsDraft: boolean) =>
  IsDraft ? RoutePath.SANDBOX_CLAUSES : RoutePath.CLAUSES;

export const openNewClauseViewModal = ({ ClauseId, IsDraft }: ClauseResponse) => {
  window.open(getRoutePath(getClausePath(IsDraft), ClauseId as RoutePath), '_blank');
};
