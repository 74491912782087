import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DocumentChatService from './documentChat.service';
import {
  AskQuestionConfig,
  ChatMessage,
  DocumentChatRequest,
  DocumentChatResponse,
  Sender,
} from './documentChat.types';
import { handleResponseError } from '../../error.helpers';

export interface DocumentChatState {
  error: string | undefined;
  loading: boolean;
  messages: ChatMessage[];
  sessionId: string | undefined;
}

const initialState: DocumentChatState = {
  error: undefined,
  loading: false,
  messages: [],
  sessionId: undefined,
};

export const documentChatSlice = createSlice({
  name: 'documentChat',
  initialState,
  reducers: {
    clearChat: (state) => {
      state.error = undefined;
      state.messages = [];
      state.sessionId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(askQuestion.pending, (state, action) => {
        state.loading = true;
        state.error = undefined;
        state.messages.push({ sender: Sender.User, text: action.meta.arg.Question });
      })
      .addCase(askQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionId = action.payload.SessionId;
        state.messages.push({ sender: Sender.LLM, text: action.payload.Answer });
      })
      .addCase(askQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const askQuestion = createAsyncThunk<
  DocumentChatResponse,
  DocumentChatRequest,
  AskQuestionConfig
>(
  'documentChat/askQuestion',
  async ({ DocumentId, Question, IdModel }, { getState, rejectWithValue }) => {
    try {
      const { sessionId: SessionId } = getState()[documentChatSlice.name];

      const { data } = await DocumentChatService.askQuestion({
        DocumentId,
        Question,
        SessionId,
        IdModel,
      });

      return data;
    } catch (e) {
      return rejectWithValue(handleResponseError(e as Error, true));
    }
  }
);

export const { clearChat } = documentChatSlice.actions;
