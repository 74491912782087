import { useCallback, useEffect, useState } from 'react';
import { DirectionFlag } from './FileViewer.types';
import { calculateScale } from './PdfViewer.helpers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { lockZoom, setHighlightDetails, setZoomLock } from '../../../store/ui/ui.slice';
import { ZOOM_INITIAL } from '../../../config/config';
import { useSearch } from './ViewerToolbar/SearchContext';

const TAG = 'mark';
const CURRENT = 'current';

export const useMarkSwitchForTxtViewer = () => {
  const dispatch = useAppDispatch();
  const { searchTerm } = useSearch();
  const [current, setCurrent] = useState(0);
  const [document, setDocument] = useState<HTMLElement | undefined>();

  const updateHighlightState = useCallback(() => {
    if (!document) return;
    const marks = document.querySelectorAll(TAG);
    const totalHighlights = marks ? marks.length : 0;
    const currentHighlight = current + 1;
    dispatch(setHighlightDetails({ totalHighlights, currentHighlight }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, document, current, searchTerm]);

  const switchMark = useCallback(() => {
    if (!document) return;
    const marks = document.querySelectorAll(TAG);
    marks?.forEach((e) => e.classList.remove(CURRENT));

    const mark = marks[current];
    mark?.classList.add(CURRENT);
    mark?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }, [current, document]);

  useEffect(() => {
    switchMark();
    updateHighlightState();
  }, [current, document, switchMark, updateHighlightState]);

  useEffect(() => {
    setCurrent(0);
  }, [searchTerm]);

  const initialize = useCallback((doc?: HTMLElement | null) => {
    doc && setDocument(doc);
  }, []);

  const handleSwitch = useCallback(
    (direction: DirectionFlag = 1) => {
      if (!document) return;
      const marks = document.querySelectorAll(TAG);

      const next = current + direction;
      setCurrent(next === marks.length ? 0 : next === -1 ? marks.length - 1 : next);

      switchMark();
    },
    [current, document, switchMark]
  );

  return { initialize, handleSwitch, switchMark };
};

export const useZoomSwitch = (url?: string, clauseMode = false) => {
  const dispatch = useAppDispatch();
  const [scale, setScale] = useState(1.5);

  const zoomLock = useAppSelector(uiSelectors.selectZoomLock);
  const zoomLocked = useAppSelector(uiSelectors.selectZoomLocked);

  useEffect(() => {
    setScale(ZOOM_INITIAL);
  }, [url, zoomLocked]);

  useEffect(() => {
    if (zoomLocked) dispatch(lockZoom());
  }, [dispatch, url, zoomLocked]);

  const handleZoom = (dir: DirectionFlag = 1) => {
    if (zoomLocked) {
      dispatch(setZoomLock(calculateScale(zoomLock, dir, clauseMode)));
    } else {
      setScale(calculateScale(scale, dir, clauseMode));
    }
  };
  return { handleZoom, scale: zoomLocked ? zoomLock : scale };
};
