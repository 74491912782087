import axiosAuth from '../../../components/Auth/axiosAuth';
import { DOCUMENT_CHAT_ENDPOINT } from '../../../services/api.service.config';
import { AxiosResponse } from 'axios';
import { DocumentChatRequest, DocumentChatResponse } from './documentChat.types';

class DocumentChatService {
  askQuestion(payload: DocumentChatRequest) {
    return axiosAuth.post<DocumentChatRequest, AxiosResponse<DocumentChatResponse>>(
      DOCUMENT_CHAT_ENDPOINT,
      payload
    );
  }
}

const documentChatService = new DocumentChatService();

export default documentChatService;
