import { documentChatSlice, DocumentChatState } from './documentChat.slice';

export enum Sender {
  'User',
  'LLM',
}

export interface ChatMessage {
  sender: Sender;
  text: string;
}

export interface AskQuestionConfig {
  state: { [documentChatSlice.name]: DocumentChatState };
  rejectValue: string;
}

export interface DocumentChatRequest {
  DocumentId: string;
  IdModel?: string;
  Question: string;
  SessionId?: string;
}

export interface DocumentChatResponse {
  Answer: string;
  Quotes: string[];
  SessionId: string;
}
