import { DirectionFlag } from './FileViewer.types';
import { TextItem } from 'pdfjs-dist/types/src/display/api';

const documentScales = [1, 2, 3];
// const documentScales = [1.5, 2, 3];
const clauseScales = [0.25, 0.5, 0.75, ...documentScales];

export const calculateScale = (scale: number, dir: DirectionFlag, clauseMode = false) => {
  const scales = clauseMode ? clauseScales : documentScales;
  const lastIndex = scales.length - 1;
  const index = scales.indexOf(scale) + dir;
  const nextIndex = index < 0 ? 0 : lastIndex < index ? lastIndex : index;
  return scales[nextIndex];
};

export const generatePages = (pages: number) => Array.from(new Array(pages), (_, i) => i + 1);

export const handleFnOnAllPagesRendered = (
  renderedPages: number[],
  page: number,
  pagesCount: number,
  fn: () => void
) => {
  let rendered = [...renderedPages, page];
  if (new Set(rendered).size === pagesCount) {
    rendered = [];
    fn();
  }
  return rendered;
};

// Mark.js can't find text in following markup: <p>This is</p><br><p>test</p>
// so there is a need to add space between 'is' and 'test'
// space is added in br tag to avoid double spaces when copying/adjusting text
export const customTextRenderer = ({ str, hasEOL }: TextItem) => {
  const space = hasEOL ? ' ' : '';
  return str + space;
};
